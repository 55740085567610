<template>
  <div class="test-container">
    <div class="inner">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>考试阅卷</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="type-bar">
        <div class="t-list">
          <div class="t-type">一级学科：</div>
          <div
            :class="firstSubjectIndex === index ? 't-item-active' : 't-item'"
            v-for="(item, index) in firstSubject"
            :key="index"
            @click="changeFirstSubject(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="t-list">
          <div class="t-type">二级学科：</div>
          <div
            :class="secondSubjectIndex === index ? 't-item-active' : 't-item'"
            v-for="(item, index) in secondSubject"
            :key="index"
            @click="changeSecondSubject(index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="wrap-row">
        <div class="tab-bar">
          <div class="t-tab">
            <div
              :class="['t-item', { 't-current': index === tabIndex }]"
              v-for="(item, index) in tabList"
              :key="index"
              @click="changeTab(index)"
            >
              {{ item }}
              <div class="t-line"></div>
            </div>
          </div>
        </div>
        <div class="search-bar ml20">
          <input
            class="s-input nohighlight"
            style="border:none;"
            type="text"
            placeholder="请输入课程名称"
            v-model.trim="examName"
            @keyup.enter="onSearch"
            @onkeyup="this.value = this.value.replace(/^ +| +$/g, '')"
          />
          <span class="s-lens" @click="onSearch"></span>
        </div>
      </div>
      <div class="data-wrap" v-show="tabIndex == 0">
        <div class="test-list" v-if="!showNoData1">
          <div class="test-item" v-for="item in coursePaper" :key="item.id">
            <div class="i-cover" :style="{ backgroundImage: 'url(' + item.coverUrl + ')' }"></div>
            <div class="i-content">
              <div class="i-name">{{ item.name }}</div>
              <span>{{ item.brief }}</span>
              <span>{{ item.startTime }}-{{ item.endTime }}</span>
              <div class="i-detail">
                <span>{{ item.signupNum }}人报名</span>
                <div class="i-btn1">{{ stateConfig[item.status] }}</div>
                <div
                  class="i-btn2"
                  @click="href('/manager/test/detail', { examId: item.id, type: 0 })"
                >
                  详情
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">
          <div class="n-image"><span>暂无数据</span></div>
        </div>
      </div>
      <div class="data-wrap" v-show="tabIndex == 1">
        <div class="test-list" v-if="!showNoData2">
          <div class="test-item" v-for="item in checkPaper" :key="item.id">
            <div class="i-cover" :style="{ backgroundImage: 'url(' + item.coverUrl + ')' }"></div>
            <div class="i-content">
              <div class="i-name">{{ item.name }}</div>
              <span>{{ item.brief }}</span>
              <span>{{ item.startTime }}-{{ item.endTime }}</span>
              <div class="i-detail">
                <span>{{ item.signupNum }}人报名</span>
                <div class="i-btn1">{{ stateConfig[item.status] }}</div>
                <div
                  class="i-btn2"
                  @click="href('/manager/test/detail', { examId: item.id, type: 1 })"
                >
                  详情
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">
          <div class="n-image"><span>暂无数据</span></div>
        </div>
      </div>
      <el-pagination
        class="page"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="prev, pager, next"
        :total="totalPages"
        :page-size.sync="pageSize"
        @current-change="currentChange"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      stateConfig: {
        0: '正在编辑',
        1: '发布成功',
        2: '考试结束'
      },
      examName: '',
      finishFlag: '',
      checkPaper: [],
      coursePaper: [],
      subjectId: '',
      // 是否有数据
      showNoData1: false,
      showNoData2: false,
      tabIndex: 0,
      tabList: ['我的课程', '需我阅卷'],
      firstSubjectID: '',
      secondSubjectID: '',
      firstSubject: [],
      secondSubject: [],
      firstSubjectIndex: 0,
      secondSubjectIndex: 0,
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 16,
      // 当前页码
      pageIndex: 1
    };
  },
  computed: {},
  created() {
    this.getFirstSubject();
    this.getCoursePaper();
    this.getCheckPaper();
  },
  mounted() {},
  watch: {},
  methods: {
    // 更改 tab
    changeTab(index) {
      this.showNoData1 = false;
      this.showNoData2 = false;
      this.tabIndex = index;
      this.pageIndex = 1;
      if (this.tabIndex == 0) {
        this.getCoursePaper();
      } else if (this.tabIndex == 1) {
        this.getCheckPaper();
      }
    },
    // 更改一级学科
    changeFirstSubject(index) {
      this.pageIndex = 1;
      this.firstSubjectIndex = index;
      this.firstSubjectID = this.firstSubject[index].id;
      this.subjectId = this.firstSubjectID;
      // 根据一级学科id 去找 二级学科
      this.getSecondSubject(this.firstSubjectID);
      if (this.tabIndex == 0) {
        this.getCoursePaper();
      } else if (this.tabIndex == 1) {
        this.getCheckPaper();
      }
    },
    // 更改二级学科
    changeSecondSubject(index) {
      this.pageIndex = 1;
      this.secondSubjectIndex = index;
      this.secondSubjectID = this.secondSubject[index].id;
      this.subjectId = this.secondSubjectID;
      if (this.tabIndex == 0) {
        this.getCoursePaper();
      } else if (this.tabIndex == 1) {
        this.getCheckPaper();
      }
    },
    // 获取一级学科列表
    getFirstSubject() {
      this.get('/biz/subject/list', { pid: '' }, e => {
        if (e.code === 200) {
          // 将一级学科索引归零
          this.firstSubjectIndex = 0;
          this.firstSubject = e.data;
          this.firstSubject.unshift({ id: '', name: '全部' });
          // this.firstSubjectID = this.firstSubject[0].id;
          this.getSecondSubject(this.firstSubject[0].id);
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 获取二级学科列表
    getSecondSubject(pid) {
      if (pid == '') {
        this.secondSubject = [];
        this.secondSubject.unshift({ id: '', name: '全部' });
      } else {
        this.get('/biz/subject/list', { pid: pid }, e => {
          if (e.code === 200) {
            // 将二级学科索引归零
            this.secondSubjectIndex = 0;
            this.secondSubject = e.data;
            this.secondSubject.unshift({ id: '', name: '全部' });
            this.secondSubjectID = this.secondSubject[0].id;
          } else {
            this.$.ui.toast(e.msg);
          }
        });
      }
    },
    // 获取需我阅卷的考试列表
    getCheckPaper() {
      this.showNoData2 = false;
      this.post(
        `/biz/teacher/qb/checkPaperExames?pageNum=${this.pageIndex}&pageSize=${this.pageSize}`,
        { examName: this.examName, finishFlag: this.finishFlag, 
		subjectId: this.subjectId ,
		//subjectId: this.firstSubjectID ,
		//secondSubjectID:this.secondSubjectID
		},
        e => {
          if (e.code == 200) {
            this.checkPaper = e.rows;
            this.totalPages = e.total;
            if (e.total == 0) {
              this.showNoData2 = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 我的课程的考试列表
    getCoursePaper() {
      this.showNoData1 = false;
      this.post(
        `/biz/teacher/qb/courseExames?pageNum=${this.pageIndex}&pageSize=${this.pageSize}`,
        { examName: this.examName, 
		finishFlag: this.finishFlag,
		subjectId: this.subjectId ,
		 //subjectId: this.firstSubjectID ,
		//secondSubjectID:this.secondSubjectID
		 },
        e => {
          if (e.code == 200) {
            this.coursePaper = e.rows;
            this.totalPages = e.total;
            if (e.total == 0) {
              this.showNoData1 = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 搜索
    onSearch() {
      if (this.tabIndex == 0) {
        this.getCoursePaper();
      } else if (this.tabIndex == 1) {
        this.getCheckPaper();
      }
    },
    // 当前页变化时执行
    currentChange(e) {
      this.pageIndex = e;
      if (this.tabIndex == 0) {
        this.getCoursePaper();
      } else if (this.tabIndex == 1) {
        this.getCheckPaper();
      }
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0
    }
  },
  components: {}
};
</script>

<style></style>
